import React, { useState } from 'react'
import Layout from '../components/Layout'
import Assassin from '../components/Assassin'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import Arrow from '../images/icons/down-chevron.svg'

const annThropeSeries = () => {
  const image = useStaticQuery(graphql`
    query {
      assassin: file(relativePath: { eq: "assassin.jpg" }) {
        childImageSharp {
          fixed(width: 270) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  // const [clickState, setClickState] = useState(false)
  // const [clickStateTwo, setClickStateTwo] = useState(false)

  return (
    <Layout>
      <Assassin />
      <div className="book-container">
        <div className="books-book">
          <div className="books">
            <Img fixed={image.assassin.childImageSharp.fixed} />
          </div>
        </div>
        <div className="synopsis">
          <div className="text-synopsis">
            <p>
              She’s a master of the space-time continuum. But with alien
              mercenaries hot on her high heels, is this assassin’s life about
              to end in a black hole? Sophisticated grandma Ann Thrope is
              exceptionally good at murder.
            </p>
            <p>
              The planet’s most dangerous professional killer, she’s just
              completed lucrative hits across the cosmos. But when word of her
              remarkable work spreads through the multiverse, she’s saddled with
              a galactic-sized price on her head…
            </p>
            <p>
              Continuing to take contracts anywhere and anywhen, Ann continues
              to dodge an onslaught of extraterrestrial bounty hunters.{' '}
            </p>
            <p>
              But when her grandchildren are put in the crosshairs, the
              stiletto-wielding senior jumps into battle to defend those she
              loves. Can this classy hitwoman take down the sinister fiends on
              her tail before she’s permanently retired?
            </p>
          </div>
          <div className="button-wrap-around">
            <div className="button-wrap">
              <a href="https://geni.us/klH44G" target="_blank" rel="noreferrer">
                EBOOK
              </a>
              <a href="https://geni.us/dOC6y" target="_blank" rel="noreferrer">
                PAPERBACK
              </a>
            </div>
          </div>
          {/* <div className="button-wrap-around">
            <div className="button-wrap">
              <button
                onClick={() => {
                  setClickState(!clickState)
                }}
              >
                EBOOK
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickState ? 'rotate(-180deg)' : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
              <button
                onClick={() => {
                  setClickStateTwo(!clickStateTwo)
                }}
              >
                PAPERBACK
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickStateTwo
                      ? 'rotate(-180deg)'
                      : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
            </div>
            {clickState ? (
              <div className="button-wrap-amazon">
                <a
                  href="https://www.amazon.com/dp/B08DV4CRMC/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596040508&sr=8-2&linkCode=ll1&tag=richardweale-20&linkId=1785a478008b67f980d02391844fe314"
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/dp/B08DV4CRMC/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596041559&sr=8-2&linkCode=ll1&tag=richardweale-21&linkId=31feb83d9ac876adfd8c01070145964d&language=en_US"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
            {clickStateTwo ? (
              <div className="button-wrap-amazon-paper">
                <a
                  href="https://www.amazon.com/Assassin-Ann-Thrope-Book-1/dp/B08Q73NMYM/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1621507422&sr=8-1"
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/Assassin-Ann-Thrope-Book-1/dp/B08Q73NMYM/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1621507313&sr=8-2"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default annThropeSeries
