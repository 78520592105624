import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Assassin = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "assassinbanner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        book: file(relativePath: { eq: "assassin3dbook.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid
      const book = data.book.childImageSharp.fluid
      return (
        <BackgroundImage className={className} fluid={image}>
          <div className="hero-wrap-assassin">
            <div className="mission-statement-assassin">
              <h1>ASSASSIN</h1>

              <Img className="assassin-book" fluid={book} />
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(Assassin)`
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
`

export default StyledBackgroundSection
